$(document).ready(function () {
   console.log('ready');
   searchElDropdown();
   handleToggleMobMenu();
   tabsTestimonials();
   validate('.form', {submitFunction: validationCallForm});
   validate('.form-header', {submitFunction: validationCallForm});
   validTextNumbers();
   tabsFAQInfo();
   changeLocationBtnInHeader();
   tabsOur();
   tabsSundance();
   tabsSidebar();
});

$(window).on('load', function () {
   console.log('load');
});

const WINDOW_WIDTH = $(document).width();

//====================================================================

const handleToggleMobMenu = () => {
   $(document).on('keydown', function (e) {
      if (e.key === 'Escape') {
         closeMobileMenu();
      }
   });

   $(document).on('click', '[data-menu-burger]', function () {
      openMobileMenu();
   });

   $(document).on('click', '[data-menu-burger-close]', function () {
      closeMobileMenu();
   });

   $(document).on('click', '.menu-header', function (e) {
      if (e.target === e.currentTarget) {
         closeMobileMenu();
      }
   });
};

const openMobileMenu = () => {
   refs.menuHeader.addClass('open');
   refs.shadowBlock.removeClass('is-hidden');
   $('body').addClass('no-scroll');
   $('.menu-header__burger').addClass('open');
};

const closeMobileMenu = () => {
   refs.menuHeader.removeClass('open');
   refs.shadowBlock.addClass('is-hidden');
   $('body').removeClass('no-scroll');
   $('.menu-header__burger').removeClass('open');

};

const tabsTestimonials = () => {
   $('.testimonials__wrapper').each(function () {
      let ths = $(this);
      ths.find('.testimonials__text').not(':first').hide();
      ths.find('.testimonials__btn').click(function () {
         ths.find('.testimonials__btn').removeClass('active').eq($(this).index()).addClass('active');
         ths.find('.testimonials__text').hide().eq($(this).index()).fadeIn();
      }).eq(0).addClass('active');
   });
};

const tabsSundance = () => {
   $('.gallery-sundance__wrapper').each(function () {
      let ths = $(this);
      ths.find('.gallery-sundance__list').not(':first').hide();
      ths.find('.gallery-sundance__btn').click(function () {
         ths.find('.gallery-sundance__btn').removeClass('active').eq($(this).index()).addClass('active');
         ths.find('.gallery-sundance__list').hide().eq($(this).index()).fadeIn();
      }).eq(0).addClass('active');
   });
};

const textArr = $('.sidebar__text[data-sidebar]');
const galleryArr = $('.gallery[data-sidebar]');
const titleArr = $('.sidebar__title[data-sidebar]');

const tabsSidebar = () => {

   $('.gallery-examples__wrapper').each(function () {
      const ths = $(this);

      if (WINDOW_WIDTH >= 667){
         ths.find('.sidebar__text').not(':first').hide();
         ths.find('.gallery').not(':first').hide();

         ths.find('.sidebar__title').click(function () {
            const thsTitle = $(this);
            const titleValue = thsTitle.data('sidebar');
            titleArr.each(function () {
               const ths = $(this);
               ths.removeClass('open');
            });
            textArr.each(function () {
               const thsText = $(this);
               const textValue = thsText.data('sidebar');
               if (titleValue !== textValue) {
                  if (WINDOW_WIDTH <= 666) thsText.fadeOut();
                  if (WINDOW_WIDTH >= 667) thsText.slideUp(300);
               }
            });
            thsTitle.addClass('open');

            textArr.each(function () {
               const thsText = $(this);
               const textValue = thsText.data('sidebar');
               if (titleValue === textValue) {
                  if (WINDOW_WIDTH <= 666) thsText.fadeIn();
                  if (WINDOW_WIDTH >= 667) thsText.slideDown(300);
               }
            });
            galleryArr.each(function () {
               const ths = $(this);
               const value = ths.data('sidebar');
               if (titleValue !== value) ths.fadeOut();
            });
            galleryArr.each(function () {
               const ths = $(this);
               const value = ths.data('sidebar');
               if (titleValue === value) ths.fadeIn();
            });
         });
      }
      if (WINDOW_WIDTH <= 666){
         titleArr.each(function (){
            const ths = $(this);
            ths.addClass('open')
         })
      }
   });
};

const tabsFAQInfo = () => {

   const textArr = $('.info-faq__text[data-slide]');
   const btnArr = $('.info-faq__btn[data-slide]');

   $('.info-faq__list').each(function () {
      const ths = $(this);
      ths.find('.info-faq__text').hide();

      ths.find('.info-faq__btn').click(function () {
         const thsBtn = $(this);
         const btnValue = thsBtn.data('slide');
         thsBtn.toggleClass('active');

         textArr.each(function () {
            const thsText = $(this);
            const textValue = thsText.data('slide');
            if (btnValue === textValue) thsText.slideToggle(300);
         });
      });

      ths.find('.info-faq__title').click(function () {
         const thsTitle = $(this);
         const titleValue = thsTitle.data('slide');

         textArr.each(function () {
            const thsText = $(this);
            const textValue = thsText.data('slide');
            if (titleValue === textValue) thsText.slideToggle(300);
         });

         btnArr.each(function () {
            const thsBtn = $(this);
            const btnValue = thsBtn.data('slide');
            if (titleValue === btnValue) thsBtn.toggleClass('active');
         });

      });
   });
};

const showForm = (success, form) => {
   $(document).on('click', '[data-success-btn]', function () {
      success.attr('data-hidden', 'is-hidden');
      form.removeClass('is-hidden');
   });
};

const showPopupSuccessForm = (form) => {
   form.addClass('is-hidden');
   const success = form.next();
   success.attr('data-hidden', ' ');
   showForm(success, form);
};

const changeLocationBtnInHeader = () => {
   if (WINDOW_WIDTH < 667) {
      const btn = $('.button.header__btn');
      $('.menu-header__wrapper').append(btn);
   }
};

const tabsOur = () => {
   $('.our__content').each(function () {
      let ths = $(this);
      ths.find('.our__info').not(':first').hide();
      ths.find('.our__btn').click(function () {
         ths.find('.our__btn').removeClass('active').eq($(this).index()).addClass('active');
         ths.find('.our__info').hide().eq($(this).index()).fadeIn();
      }).eq(0).addClass('active');
   });
};

$(document).on('click', '.menu-header__item > a', function (e) {
   const ths = $(this);
   if (e.target === e.currentTarget) {
      const value = ths.text().trim();
      $('.sub-menu__btn-back a').text(value);
      ths.next().addClass('open');
   }
});

$(document).on('click', '.sub-menu__btn-back', function () {
   $('.sub-menu__wrapper').removeClass('open');
});