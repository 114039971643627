/* ============================ refs START ================================================================= */
const refs = {
   dropDownList: $('.dropdown__list'),
   dropDownBtn: $('.dropdown__button'),
   dropDownItems: $('.dropdown__item'),
   dropDownInput: $('.dropdown__input-hidden'),
   menuHeader: $('.menu-header'),
   shadowBlock: $('.shadow'),
}
/* ============================ refs END ================================================================= */
