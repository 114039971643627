/* ============================ sliders.js START ================================================================= */
const sliderBestFences = new Swiper('.slider-best-fences', {
   direction: 'horizontal',
   effect: 'slide',
   loop: true,
   spaceBetween: 15,
   navigation: {
      nextEl: '.slider-best-fences__button-next',
      prevEl: '.slider-best-fences__button-prev',
   },
   pagination: {
      el: '.slider-best-fences__pagination',
      clickable: true,
   },
   keyboard: {
      enable: true,
      onlyInViewport: true,
   },
   // when window width is >= 667px
   breakpoints: {
      667: {
         direction: 'vertical',
         spaceBetween: 30,
      },
   },
});

const sliderInGallery = new Swiper('.slider-in-gallery', {
   spaceBetween: 10,
   slidesPerView: 'auto',
   watchSlidesProgress: true,
   freeMode: true,
});

const sliderGallery = new Swiper('.slider-gallery', {
   spaceBetween: 10,
   keyboard: {
      enable: true,
      onlyInViewport: true,
   },
   navigation: {
      nextEl: '.slider-gallery__button-next',
      prevEl: '.slider-gallery__button-prev',
   },
   thumbs: {
      swiper: sliderInGallery,
   },
   pagination: {
      el: '.slider-gallery__pagination',
      clickable: true,
   },
});

const sliderTeam = new Swiper('.slider-team', {
   loop: true,
   effect: 'slide',
   spaceBetween: 15,
   navigation: {
      nextEl: '.slider-team__button-next',
      prevEl: '.slider-team__button-prev',
   },
   pagination: {
      el: '.slider-team__pagination',
      clickable: true,
   },
   keyboard: {
      enable: true,
      onlyInViewport: true,
   },
   // when window width is >= px
   breakpoints: {
      320: {
         slidesPerView: 1,
      },
      667: {
         slidesPerView: 4,
      },
      992: {
         spaceBetween: 20,
         slidesPerView: 4,
      },
      1680: {
         spaceBetween: 40,
         slidesPerView: 4,
      },
   },
});

const sliderTemplate = new Swiper('.template-slider', {
   effect: 'fade',
   loop: true,
   fadeEffect: {
      crossFade: true,
   },
   navigation: {
      nextEl: '.template-slider__button-next',
      prevEl: '.template-slider__button-prev',
   },
   pagination: {
      el: '.template-slider__pagination',
      clickable: true,
   },
   keyboard: {
      enable: true,
      onlyInViewport: true,
   },
   autoHeight: true,
});

const sliderTemplateReverse = new Swiper('.template-slider_reverse', {
   effect: 'fade',
   loop: true,
   fadeEffect: {
      crossFade: true,
   },
   navigation: {
      nextEl: '.template-slider_reverse__button-next',
      prevEl: '.template-slider_reverse__button-prev',
   },
   pagination: {
      el: '.template-slider_reverse__pagination',
      clickable: true,
   },
   keyboard: {
      enable: true,
      onlyInViewport: true,
   },
   autoHeight: true,
});

const sliderDesignFence = new Swiper('.design-fence-slider', {
   effect: 'fade',
   loop: true,
   fadeEffect: {
      crossFade: true,
   },
   navigation: {
      nextEl: '.design-fence-slider__button-next',
      prevEl: '.design-fence-slider__button-prev',
   },
   pagination: {
      el: '.design-fence-slider__pagination',
      clickable: true,
   },
   keyboard: {
      enable: true,
      onlyInViewport: true,
   },
   autoHeight: true,
});

if ($(window).width() <= 666) {
   const sidebar = new Swiper('.sidebar', {
      spaceBetween: 30,
      navigation: {
         nextEl: '.sidebar__button-next',
         prevEl: '.sidebar__button-prev',
      },
      pagination: {
         el: '.sidebar__pagination',
         clickable: true,
      },
      keyboard: {
         enable: true,
         onlyInViewport: true,
      },
   });

   const gallery = new Swiper('.gallery-slider', {
      spaceBetween: 30,
      navigation: {
         nextEl: '.gallery-slider__button-next',
         prevEl: '.gallery-slider__button-prev',
      },
      pagination: {
         el: '.gallery-slider__pagination',
         clickable: true,
      },
      keyboard: {
         enable: true,
         onlyInViewport: true,
      },
      autoHeight: true,
   });
   sidebar.controller.control = gallery;
   gallery.controller.control = sidebar;
}

/* ============================ sliders.js END ================================================================= */